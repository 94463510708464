<template>
  <div>
    <b-modal
      :id="componentName"
      no-close-on-backdrop
      hide-footer
      centered
      size="lg"
      @shown="btnModal('show')"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          관리자 {{ selectedAssetTypeLabel || '금액' }} 지급
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click.prevent="btnModal('close')"
          />
        </div>
      </template>

      <!-- Modal Body -->
      <b-card no-body>
        <validation-observer ref="simpleRules">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  class="font-small-3"
                  :label="`지급선택`"
                  label-for="user-asset-type"
                  label-cols-md="4"
                  label-align="left"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    mode="passive"
                    name="지급선택"
                  >
                    <div class="d-flex">
                      <b-form-radio-group
                        id="user-asset-type"
                        v-model="selectedAssetType"
                        :options="optionsAssetType"
                        :state="errors.length > 0 ? false : null"
                        @input="handleAsset('assetType')"
                      />
                    </div>
                    <small class="text-danger"> {{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="font-small-3"
                  :label="`현재 보유한 ${selectedAssetTypeLabel || '금액'}`"
                  label-for="user-asset-asisAsset"
                  label-cols-md="4"
                  label-align="left"
                >
                  <b-form-input
                    id="user-asset-asisAsset"
                    :value="asset.asisAsset | formatAmount"
                    class="font-small-3 cursor-pointer custom-readonly-input"
                    size="sm"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="font-small-3"
                  :label="`지급 할 ${selectedAssetTypeLabel || '금액'}`"
                  label-for="user-asset-updateAsset"
                  label-cols-md="4"
                  label-align="left"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    mode="passive"
                    :name="`지급 할 ${selectedAssetTypeLabel || '금액'}`"
                  >
                    <b-form-input
                      id="user-asset-updateAsset"
                      v-model.lazy="asset.updateAsset"
                      placeholder="지급할 캐쉬 및 포인트를 이곳에 입력해주세요."
                      class="font-small-3 cursor-pointer custom-placeholder-color"
                      type="number"
                      size="sm"
                      :state="errors.length > 0 ? false : null"
                      @input="handleAsset('updateAsset')"
                      @click="handleAsset('validAssetType')"
                    />
                    <small class="text-danger"> {{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="font-small-3"
                  :label="`적용된 ${selectedAssetTypeLabel || '금액'}`"
                  label-for="user-asset-tobeAsset"
                  label-cols-md="4"
                  label-align="left"
                >
                  <b-form-input
                    id="user-asset-tobeAsset"
                    :value="asset.tobeAsset | formatAmount"
                    class="font-small-3 cursor-pointer custom-readonly-input"
                    size="sm"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="font-small-3"
                  :label="'메모'"
                  label-for="user-asset-memo"
                  label-cols-md="4"
                  label-align="left"
                >
                  <b-form-input
                    id="user-asset-memo"
                    v-model="asset.memo"
                    class="font-small-3 cursor-pointer"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                  size="sm"
                  class="float-right"
                  @click.prevent="btnSave"
                >
                  저장
                </b-button>
                <b-button
                  ref="reset"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="reset"
                  variant="primary"
                  size="sm"
                  class="float-right mr-2"
                  @click.prevent="btnModal('close')"
                >
                  취소
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BForm, BFormGroup, BButton,
  BFormInput,
  BFormRadioGroup,
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'

import validation from '@/views/apps/user/_formValidation'
import fncShowSwal from '@/views/apps/user/_vueSweetalert2'

import { createNamespacedHelpers } from 'vuex'
import { UPDATE_USERS_ASSET } from '@/store/users/mutation'

const userStore = createNamespacedHelpers('userStore')

export default {
  name: 'UserViewUserAsset',
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    BFormInput,
    BFormRadioGroup,
    BModal,

    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatAmount(value) {
      if (value === '' || value === undefined) {
        return value // 빈 문자열일 경우 그대로 반환
      }
      // 금액을 형식화하는 커스텀 필터
      // type1: value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' 원'; // 단위(천)표시 / 소수점 2자리 / "원"을 추가
      // type2: value.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // 단위(천)표시
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      userIp: localStorage.getItem('ip'),
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,
      // user: JSON.parse(localStorage.getItem('selectedUserViewInfo')),

      selectedAssetType: '',
      optionsAssetType: [
        { text: '캐쉬(통합머니)', value: 'cash', disabled: false },
        { text: '포인트', value: 'point', disabled: false },
      ],
      asset: {
        type: '',
        updateAsset: '',
        asisAsset: '',
        tobeAsset: '',
        memo: '',
      },

      // validations
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,
    }
  },
  computed: {
    ...userStore.mapGetters({
      user: 'user',
    }),
    selectedAssetTypeLabel() {
      // 선택된 라디오 버튼의 텍스트를 라벨로 사용
      const selectedOption = this.optionsAssetType.find(item => item.value === this.selectedAssetType)
      return selectedOption ? selectedOption.text : ''
    },
  },
  // created() {
  //   console.log(this.componentName, 'created()')
  // },
  // mounted() {
  //   console.log(this.componentName, 'mounted()')
  // },
  methods: {
    ...userStore.mapActions({
      $updateUsersAsset: UPDATE_USERS_ASSET,
    }),

    init() {
      this.asset.type = ''
      this.asset.asisAsset = ''
      this.asset.updateAsset = ''
      this.asset.tobeAsset = ''
      this.selectedAssetType = ''
      this.asset.memo = ''
    },

    handleAsset(target) {
      if (target === 'assetType') {
        console.log(this.componentName, 'handleAsset()', target, this.selectedAssetType)
        this.asset.type = this.selectedAssetType
        this.asset.asisAsset = this.user[this.selectedAssetType]
        this.asset.updateAsset = ''
        this.asset.tobeAsset = ''
        this.asset.memo = ''
      }
      if (target === 'validAssetType') {
        if (!this.selectedAssetType) {
          fncShowSwal('error', '지급선택을 하시기 바랍니다.')
          this.asset.updateAsset = ''
        }
      }
      if (target === 'updateAsset') {
        // console.log(this.componentName, 'handleAsset()', target, this.selectedAssetType)
        this.asset.tobeAsset = this.asset.updateAsset !== '' ? Number(this.user[this.selectedAssetType]) + Number(this.asset.updateAsset) : ''
      }
    },

    // Button
    async btnSave() {
      // console.log(this.componentName, 'btnSave()')
      try {
        // Validation Check
        if (!await validation(this.$refs.simpleRules)) return false

        // Admin Payment
        const message = `${this.selectedAssetTypeLabel} "${this.asset.updateAsset.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원" 을 \n 적용하시겠습니까?`
        const result = await fncShowSwal('confirm', message)
        if (result.isConfirmed) {
          await this.$updateUsersAsset({
            site: JSON.parse(localStorage.getItem('userView')).site,
            userid: JSON.parse(localStorage.getItem('userView')).userid,
            memo: this.asset.memo,
            adminId: this.userData.userid,
            adminIp: this.userIp,
            [this.asset.type]: parseInt(this.asset.updateAsset, 10),
          })
            .then(async res => {
              if (res) {
                await fncShowSwal('success', '지급완료')
                this.btnModal('close')
              }
            })
        }
      } catch (e) {
        await fncShowSwal('error')
      }
    },
    btnReset() {
      this.init()
    },

    // Modal
    btnModal(target) {
      if (target === 'show') {
        // console.log(this.componentName, 'btnModal()', target)
      }
      if (target === 'close') {
        // console.log(this.componentName, 'btnModal()', target)
        this.btnReset()
        this.$bvModal.hide(this.componentName)
      }
    },
  },
}
</script>

<style>
/* placeholder */
.custom-placeholder-color::placeholder {
  color: rgb(168, 226, 51) !important;
}

.custom-readonly-input[readonly] {
  background-color: transparent !important; /* 배경색을 투명하게 */
  color: rgb(226,167,75) !important; /* 텍스트 색상 강제 적용 */
  opacity: 1 !important; /* readonly 상태의 투명도를 제거 */
}
</style>
