<template>
  <div>
    <b-card no-body>
      <!-- Search Condition -->
      <b-row class="justify-content-between">
        <b-col
          lg="3"
          md="4"
        >
          <b-form-group
            class="font-small-3"
            label="구분"
            label-for="betHistory-select"
            label-cols-lg="3"
            label-cols-md="4"
            label-align-md="left"
          >
            <b-form-select
              id="betHistory-select"
              v-model="selectedBetHistoryOptions"
              class="font-small-3 half-width"
              :options="betHistoryOptions"
              size="sm"
              style="color:rgb(229,166,48)"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="8"
        >
          <b-form-group
            class="font-small-3"
            label="승인일자"
            label-for="betHistory-period"
            label-cols-md="2"
            label-align-md="right"
          >
            <b-input-group
              id="betHistory-period"
              class="calendar-size font-small-3"
            >
              <b-form-datepicker
                v-model="periodFromFinish"
                class=""
                boundary="window"
                locale="ko"
                placeholder="시작일"
                aria-controls="example-input"
                size="sm"
                :max="periodToFinish"
              />
              <div class="pl-1 pr-1">
                ~
              </div>
              <b-form-datepicker
                v-model="periodToFinish"
                class=""
                boundary="window"
                locale="ko"
                placeholder="종료일"
                aria-controls="example-input"
                size="sm"
                :min="periodFromFinish"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Table -->
      <b-row class="justify-content-between">
        <!-- Table Fitler -->
        <b-col
          lg="3"
          md="4"
        >
          <b-form-group
            class="font-small-3"
            label="Show"
            label-for="betHistory-perPage"
            label-cols-lg="3"
            label-cols-md="4"
            label-align-md="left"
          >
            <b-form-select
              id="betHistory-perPage"
              v-model="perPage"
              class="half-width"
              size="sm"
              :options="perPageOptions"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="8"
        >
          <b-form-group
            class="font-small-3"
            label="검색"
            label-for="betHistory-searchFilter"
            label-cols-md="2"
            label-align-md="right"
          >
            <b-input-group size="sm">
              <b-form-input
                id="betHistory-searchFilter"
                v-model="searchFilter"
                type="search"
                placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
              />
              <b-input-group-append>
                <b-button
                  :disabled="!searchFilter"
                  @click="searchFilter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
<!--        <b-col-->
<!--          cols="2"-->
<!--          class="d-flex justify-content-end"-->
<!--        >-->
<!--          <b-form-group class="font-small-3">-->
<!--            <b-form-checkbox-->
<!--              v-model="isExpandChecked"-->
<!--              class="float-left"-->
<!--              checked="false"-->
<!--              switch-->
<!--            >-->
<!--              <span class="switch-icon-left">-->
<!--                <feather-icon icon="CornerRightDownIcon" />-->
<!--              </span>-->
<!--              <span class="switch-icon-right">-->
<!--                <feather-icon icon="CropIcon" />-->
<!--              </span>-->
<!--            </b-form-checkbox>-->
<!--          </b-form-group>-->
<!--        </b-col>-->
        <!-- Table Content -->
        <b-col sm="12">
          <b-table
            responsive
            striped
            hover
            small
            show-empty
            empty-text="No matching records found"
            class="no-select"
            style="text-align: center; font-size: small"
            :items="paginatedItems"
            :fields="betHistoryFields"
            @row-clicked="onRowClick"
          >
            <template #cell(index)="data">
              <div style="text-align: center; min-width: 3rem">
                <!--  ASC --> <!-- {{ data.index + 1 + (currentPage - 1) * perPage }} -->
                <!--  DESC -->{{ totalRows - data.index }}
              </div>
            </template>
            <template #cell(site)="data">
              <div style="text-align: center; min-width: 4rem">
                {{ data.item.site }}
              </div>
            </template>
            <template #cell(requestId)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 5rem"
              >
                {{ data.item.requestId }}({{ data.item.requestNickname }})
              </div>
            </template>
            <template #cell(category)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 8rem"
              >
                {{ data.item.category }}
              </div>
            </template>
            <template #cell(betDate)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 10rem"
              >
                {{ data.item.betDate }}
              </div>
            </template>
            <template #cell(fold)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 2rem"
              >
                {{ data.item.fold }}
              </div>
            </template>
            <template #cell(betCash)="data">
              <div
                size="sm"
                style="text-align: right; min-width: 4rem; color:rgb(196,186,138)"
              >
                {{ Number(data.item.betCash).toLocaleString() }}
              </div>
            </template>
            <template #cell(totalOdds)="data">
              <div
                size="sm"
                style="text-align: right; min-width: 3rem; color:rgb(196,186,138)"
              >
                {{ Number(data.item.totalOdds).toLocaleString() }}
              </div>
            </template>
            <template #cell(totalHitCash)="data">
              <div
                size="sm"
                style="text-align: right; min-width: 4rem; color:rgb(196,186,138)"
              >
                {{ Number(data.item.totalHitCash).toLocaleString() }}
              </div>
            </template>
            <template #cell(requestIp)="data">
              <div
                v-b-tooltip.hover.bottom.v-danger="data.item.requestIp"
                size="sm"
                style="text-align: center;"
              >
                {{ truncateText(data.item.requestIp, 15) }}
              </div>
            </template>
            <template #cell(betResult)="data">
              <div
                :style="{
                  'text-align': 'center',
                  'min-width': '4rem',
                  'font-size': 'small',
                  'color': getBetResultColor(data.item.betResult)
                }"
                size="sm"
              >
                <div
                  v-if="data.item.confirmed === 'ALL TIE'"
                  size="sm"
                  style="text-align: center; min-width: 3rem; color: yellow; font-size: x-small"
                >
                  배팅금반환
                </div>
                <div
                  v-if="data.item.confirmed === 'CUSTOM CANCELED'"
                  size="sm"
                  style="text-align: center; min-width: 3rem; color: yellow; font-size: x-small"
                >
                  배팅금반환
                </div>
                <div
                  v-if="data.item.confirmed !== 'CUSTOM CANCELED' || data.item.confirmed !== 'CUSTOM CANCELED'"
                  size="sm"
                >
                  {{ getConfirmedText(data.item.confirmed) }}
                </div>
              </div>
            </template>
            <template #cell(confirmed)="data">
              <div
                :style="{
                  'text-align': 'center',
                  'min-width': '5rem',
                  'font-size': 'small',
                  'color': getConfirmedColor(data.item.confirmed)
                }"
                size="sm"
              >
                <div
                  v-if="data.item.confirmed === 'CUSTOM CANCELED'"
                  size="sm"
                  style="text-align: center; min-width: 3rem; color: yellow; font-size: x-small"
                >
                  수동배팅취소
                </div>
                <div
                  v-if="data.item.confirmed !== 'CUSTOM CANCELED'"
                  size="sm"
                >
                  {{ getConfirmedText(data.item.confirmed) }}
                </div>
              </div>
            </template>

            <template #row-details="row">
              <b-card
                class="p-0"
                style="background-color: #212026;"
                @click="onRowClick(row.item)"
              >
                <UserTabBetHistoryDetail :record="row.item" />
              </b-card>
            </template>
            <template
              v-if="fetchRequestAlarmsBetFinish.length > 0"
              slot="bottom-row"
            >
              <td
                colspan="7"
                style="background-color: #24233a; color:rgb(196,186,138)"
              >
                Total
              </td>
              <td
                class="text-right"
                style="background-color: #24233a; color:rgb(196,186,138)"
              >
                <div
                  class="text-right"
                  style="color: #3df9ff"
                >
                  {{ Number(getTotal('betCash')).toLocaleString() }}
                </div>
              </td>
              <td
                class="text-right"
                style="background-color: #24233a; color:rgb(196,186,138)"
              />
              <td style="background-color: #24233a; color:rgb(196,186,138)">
                <div
                  class="text-right"
                  style="color: #3df9ff"
                >
                  {{ Number(getTotal('totalHitCash')).toLocaleString() }}
                </div>
              </td>
              <td
                colspan="3"
                class="text-right"
                style="background-color: #24233a; color:rgb(196,186,138)"
              />
            </template>
          </b-table>
        </b-col>

        <!-- Table Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
        >
          <span class="font-small-2">{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }} 건 </span>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol,
  BFormGroup,
  BFormInput, BInputGroup, BInputGroupAppend,
  BFormSelect, BFormCheckbox,
  BButton, BFormDatepicker,
  BTable, BPagination,
  VBTooltip, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { createNamespacedHelpers } from 'vuex'
import { FETCH_REQUEST_ALARMS_BET_FINISH } from '@/store/notice/action'

import UserTabBetHistoryDetail from '@/views/apps/user/users-view/users-tab/UserTabBetHistoryDetail.vue'

import * as moment from 'moment-timezone'

const noticeStore = createNamespacedHelpers('noticeStore')
moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

export default {
  name: 'UserTabUserBetHistory',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BFormDatepicker,
    BTable,
    BPagination,

    UserTabBetHistoryDetail,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userView')),

      // Searh Conditon
      selectedBetHistoryOptions: null,
      betHistoryOptions: [
        { value: null, text: '전체', selected: true },
        { value: 'REQUEST', text: '승인요청(대기)' },
        { value: 'YET', text: '미결과' },
        { value: 'NONE', text: '미환급' },
        { value: 'CONFIRMED', text: '환급완료' },
        { value: 'REJECT', text: '환급부결' },
        { value: 'CANCELED', text: '배팅취소' },
      ],
      periodFromFinish: moment(new Date()).startOf('month').format(fmt1),
      periodToFinish: moment(new Date()).endOf('month').format(fmt1),

      // TableOption
      searchFilter: '',
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 10, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0, // 총 메시지 수 (서버에서 얻을 수도 있습니다)
      isExpandChecked: false,

      // TableContent
      betHistoryItems: [],
      betHistoryFields: [
        { key: 'index', sortable: false, label: 'No.', thClass: 'col1' },
        { key: 'site', sortable: false, label: '사이트', thClass: 'col1' },
        { key: 'requestId', sortable: false, label: '아이디(닉네임)', thClass: 'col5' },
        { key: 'betDate', sortable: false, label: '배팅일시', thClass: 'col3' },
        { key: 'sports', sortable: false, label: '구분', thClass: 'col2' },
        { key: 'category', sortable: false, label: '카테고리', thClass: 'col2' },
        { key: 'fold', sortable: false, label: '폴드', thClass: 'col3' },
        { key: 'betCash', sortable: false, label: '배팅금액', thClass: 'col3' },
        { key: 'totalOdds', sortable: false, label: '총배당', thClass: 'col3' },
        { key: 'totalHitCash', sortable: false, label: '적중금액', thClass: 'col3' },
        { key: 'requestIp', sortable: false, label: 'IP', thClass: 'col3' },
        { key: 'betResult', sortable: false, label: '적중', thClass: 'col3' },
        { key: 'confirmed', sortable: false, label: '상태', thClass: 'col6' },
        // { key: 'edit', sortable: false, label: '처리', thClass: 'col10', },
      ],
    }
  },
  computed: {
    ...noticeStore.mapGetters({
      fetchRequestAlarmsBetFinish: 'fetchRequestAlarmsBetFinish',
    }),

    // 검색필터된 Items
    filteredItems() {
      let filteredItems = this.fetchRequestAlarmsBetFinish

      if (this.searchFilter) {
        filteredItems = filteredItems.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      }

      if (this.selectedBetHistoryOptions) {
        filteredItems = filteredItems.filter(item => item.confirmed && item.confirmed.toString().toLowerCase().includes(this.selectedBetHistoryOptions.toLowerCase()))
      }

      if (this.periodFromFinish && this.periodToFinish) {
        filteredItems = filteredItems.filter(item => moment(item.betDate).isBetween(moment(this.periodFromFinish), moment(this.periodToFinish), undefined, '[]'))
      }
      // console.log('filteredItems', filteredItems)
      return filteredItems
    },

    // 검색필터된 Items => 페이징처리된 Items
    paginatedItems() {
      const totalFilteredItems = this.filteredItems.length
      const start = totalFilteredItems - (this.currentPage * this.perPage)
      const end = start + this.perPage

      const paginatedItems = this.filteredItems.slice(Math.max(0, start), end).reverse()

      this.totalRows = totalFilteredItems
      this.pageFrom = (this.currentPage - 1) * this.perPage + 1 // 현재 페이지의 첫 번째 항목
      this.pageTo = Math.min(this.totalRows, this.currentPage * this.perPage) // 현재 페이지의 마지막 항목 또는 전체 항목 수 중 작은 값
      this.pageOf = this.totalRows

      return paginatedItems
    },
  },
  watch: {
    // selectedBetHistoryOptions() {
    //   this.fetchData()
    // },
    // periodFromFinish() {
    //   this.fetchData()
    // },
    // periodToFinish() {
    //   this.fetchData()
    // },
    isExpandChecked(newVal) {
      this.fetchRequestAlarmsBetFinish.forEach(item => {
        this.$set(item, '_showDetails', newVal)
      })
    },
  },
  created() {
    // console.log("UserTabUserBetHistory created()")
  },
  mounted() {
    // console.log('UserTabUserBetHistory mounted()')
    this.fetchData()
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchRequestAlarmsBetFinish: FETCH_REQUEST_ALARMS_BET_FINISH,
    }),

    async fetchData() {
      // console.log('UserTabUserBetHistory fetchData()')

      // console.log(
      //   '[FETCH_REQUEST_ALARMS_BET_FINISH] ====> ',
      //   'site', this.userData.site,
      //   'requestId', this.userData.userid,
      //   'confirmed', this.selectedBetHistoryOptions,
      //   'periodFrom', this.periodFromFinish,
      //   'periodTo', this.periodToFinish,
      // )
      await this.$fetchRequestAlarmsBetFinish({
        site: this.userData.site,
        requestId: this.userData.userid,
        // periodFrom: this.periodFromFinish.split(' ')[0],
        // periodTo: this.periodToFinish.split(' ')[0],
        // ...(this.selectedBetHistoryOptions ? { confirmed: this.selectedBetHistoryOptions } : {}),
      })
    },

    // table Filter
    getBetResultColor(betResult) {
      const colorMap = {
        WIN: 'limegreen',
        LOSS: 'red',
        'ALL TIE': 'yellow',
        'CUSTOM CANCELED': 'yellow',
      }
      return colorMap[betResult] || 'gray'
    },
    getBetResultText(betResult) {
      const textMap = {
        WIN: '적중',
        LOSS: '미적중',
        'ALL TIE': '배팅금반환',
        'CUSTOM CANCELED': '배팅금반환',
      }
      return textMap[betResult] || ''
    },
    getConfirmedColor(confirmed) {
      const colorMap = {
        REQUEST: 'gray',
        YET: 'gray',
        NONE: 'gray',
        CONFIRMED: 'limegreen',
        REJECT: 'red',
        'CUSTOM CANCELED': 'yellow',
        'ALL TIE': 'yellow',
      }
      return colorMap[confirmed] || 'gray' // 기본 색상
    },
    getConfirmedText(confirmed) {
      const textMap = {
        REQUEST: '대기',
        YET: '미결과',
        NONE: '미환급',
        CONFIRMED: '환급',
        REJECT: '환급부결',
        'CUSTOM CANCELED': '수동배팅취소',
        'ALL TIE': '전체적특',
      }
      return textMap[confirmed] || confirmed
    },
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      }
      return `${text.substring(0, maxLength)}...`
    },
    getTotal(field) {
      // return this.fetchRequestAlarmsBetFinish.reduce((acc, item) => acc + item[field], 0)
      return this.paginatedItems.reduce((acc, item) => acc + item[field], 0)
    },
    // Table Row Clicked
    onRowClick(item) {
      this.$set(item, '_showDetails', !item._showDetails)
    },
  },
}
</script>
