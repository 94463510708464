<template>
  <div>
    <b-card no-body>
      <!-- Search Condition -->
      <b-row class="justify-content-between">
        <b-col
          lg="3"
          md="4"
        >
          <b-form-group
            class="font-small-3"
            label="구분"
            label-for="exchange-select"
            label-cols-lg="3"
            label-cols-md="4"
            label-align="left"
          >
            <b-form-select
              id="exchange-select"
              v-model="selectedExchangeOptions"
              class="font-small-3 half-width"
              :options="exchangeOptions"
              size="sm"
              style="color:rgb(229,166,48)"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="8"
        >
          <b-form-group
            class="font-small-3"
            label="승인일자"
            label-for="exchange-period"
            label-cols-md="2"
            label-align-md="right"
          >
            <b-input-group
              id="exchange-period"
              class="calendar-size font-small-3"
            >
              <b-form-datepicker
                v-model="periodFromFinish"
                class=""
                boundary="window"
                locale="ko"
                placeholder="시작일"
                aria-controls="example-input"
                size="sm"
                :max="periodToFinish"
              />
              <div class="pl-1 pr-1">
                ~
              </div>
              <b-form-datepicker
                v-model="periodToFinish"
                class=""
                boundary="window"
                locale="ko"
                placeholder="종료일"
                aria-controls="example-input"
                size="sm"
                :min="periodFromFinish"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Table -->
      <b-row class="justify-content-between">
        <!-- Table Fitler -->
        <b-col
          lg="3"
          md="4"
        >
          <b-form-group
            class="font-small-3"
            label="Show"
            label-for="exchange-perPage"
            label-cols-lg="3"
            label-cols-md="4"
            label-align-sm="left"
          >
            <b-form-select
              id="exchange-perPage"
              v-model="perPage"
              class="half-width"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              size="sm"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="8"
        >
          <b-form-group
            class="font-small-3"
            label="검색"
            label-for="exchange-searchFilter"
            label-cols-md="2"
            label-align-md="right"
          >
            <b-input-group size="sm">
              <b-form-input
                id="exchange-searchFilter"
                v-model="searchFilter"
                type="search"
                placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
              />
              <b-input-group-append>
                <b-button
                  :disabled="!searchFilter"
                  @click="searchFilter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Table Content -->
        <b-col sm="12">
          <b-table
            responsive
            striped
            hover
            small
            show-empty
            empty-text="No matching records found"
            style="text-align: center; font-size: small"
            :items="paginatedItems"
            :fields="exchangeFields"
          >
            <template #cell(index)="data">
              <div style="text-align: center; min-width: 3rem">
                {{ data.idx }}
              </div>
            </template>
            <template #cell(site)="data">
              <div style="text-align: center; min-width: 4rem">
                {{ data.item.site }}
              </div>
            </template>
            <template #cell(requestId)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 5rem"
              >
                {{ data.item.requestId }}({{ data.item.requestNickname }})
              </div>
            </template>
            <template #cell(requestDate)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 7rem"
              >
                {{ data.item.requestDate ? data.item.requestDate.replace(/^(\d{2})(\d{2}).(\d{2}).(\d{2}) (\d{2}:\d{2}):\d{2}$/, "$2.$3.$4 $5") : '' }}
              </div>
            </template>
            <template #cell(requestRank)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 2rem"
              >
                {{ data.item.requestRank }}
              </div>
            </template>
            <template #cell(requestType)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 2rem"
              >
                {{ data.item.requestType }}
              </div>
            </template>
            <template #cell(requestIp)="data">
              <div
                v-b-tooltip.hover.bottom.v-danger="data.item.requestIp"
                size="sm"
                style="text-align: center;"
              >
                {{ truncateText(data.item.requestIp, 15) }}
              </div>
            </template>
            <template #cell(requestCash)="data">
              <div
                size="sm"
                style="text-align: right; min-width: 4rem; color:rgb(196,186,138)"
              >
                {{ Number(data.item.requestCash).toLocaleString() }}
              </div>
            </template>
            <template #cell(requestName)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 4rem"
              >
                {{ data.item.requestName }}
              </div>
            </template>
            <template #cell(accountBank)="data">
              <div
                size="sm"
                style="color:rgb(196,186,138); text-align: center; min-width: 6rem"
              >
                {{ data.item.accountBank }}
              </div>
            </template>
            <template #cell(accountNumber)="data">
              <div
                size="sm"
                style="color:rgb(196,186,138); text-align: center; min-width: 8rem;"
              >
                {{ data.item.accountNumber }}
              </div>
            </template>
            <template #cell(confirmId)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 5rem"
                class="text-success"
              >
                {{ data.item.confirmId }}
              </div>
            </template>
            <template #cell(confirmIp)="data">
              <div
                v-b-tooltip.hover.bottom.v-danger="data.item.confirmIp"
                size="sm"
                class="text-success"
                style="text-align: center;"
              >
                {{ truncateText(data.item.confirmIp, 15) }}
              </div>
            </template>
            <template #cell(confirmAt)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 7rem;"
                class="text-success"
              >
                {{ data.item.confirmAt ? data.item.confirmAt.replace(/^(\d{2})(\d{2}).(\d{2}).(\d{2}) (\d{2}:\d{2}):\d{2}$/, "$2.$3.$4 $5") : '' }}
              </div>
            </template>
            <template #cell(confirmed)="data">
              <div
                v-if="data.item.confirmed === 'CONFIRMED'"
                size="sm"
                style="text-align: center; min-width: 3rem;"
                class="text-success"
              >
                승인
              </div>
              <div
                v-if="data.item.confirmed === 'REJECT'"
                size="sm"
                style="text-align: center; min-width: 3rem; color: red"
              >
                부결
              </div>
              <div
                v-if="data.item.confirmed === 'CANCELED'"
                size="sm"
                style="text-align: center; min-width: 3rem; color: yellow; font-size: x-small"
              >
                환전 취소
              </div>
            </template>
            <template
              v-if="fetchRequestAlarmsExchangeFinish.length > 0"
              slot="bottom-row"
            >
              <td
                colspan="7"
                style="background-color: #24233a; color:rgb(196,186,138)"
              >
                Total
              </td>
              <td
                class="right"
                style="background-color: #24233a; color:rgb(196,186,138)"
              >
                <div
                  class="text-right"
                  style="color: #3df9ff; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('requestCash')).toLocaleString() }}
                </div>
              </td>
              <td
                colspan="7"
                style="background-color: #24233a; color:rgb(196,186,138)"
              />
            </template>
          </b-table>
        </b-col>

        <!-- Table Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
        >
          <span class="font-small-2">{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }} 건 </span>
        </b-col>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol,
  BFormGroup,
  BFormInput, BInputGroup, BInputGroupAppend,
  BFormSelect,
  BButton, BFormDatepicker,
  BTable, BPagination,
  VBTooltip, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { createNamespacedHelpers } from 'vuex'
import { FETCH_REQUEST_ALARMS_EXCHANGE_FINISH } from '@/store/notice/action'

import * as moment from 'moment-timezone'

const noticeStore = createNamespacedHelpers('noticeStore')
moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

export default {
  name: 'UserTabUserExchange',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BButton,
    BFormDatepicker,
    BTable,
    BPagination,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userView')),

      // Searh Conditon
      selectedExchangeOptions: null,
      exchangeOptions: [
        { value: null, text: '전체', selected: true },
        { value: 'REQUEST', text: '환전요청' },
        { value: 'CONFIRMED', text: '승인완료' },
        { value: 'REJECT', text: '충전부결' },
        { value: 'CANCELED', text: '충전취소' },
      ],
      periodFromFinish: moment(new Date()).startOf('month').format(fmt1),
      periodToFinish: moment(new Date()).endOf('month').format(fmt1),

      // TableOption
      searchFilter: '',
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 10, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0, // 총 메시지 수 (서버에서 얻을 수도 있습니다)

      // TableContent
      exchangeItems: [],
      exchangeFields: [
        { key: 'idx', sortable: false, label: 'No.', thClass: 'col1' },
        { key: 'site', sortable: false, label: '사이트', thClass: 'col1' },
        { key: 'recommendNickname', sortable: false, label: '상위', thClass: 'col5' },
        { key: 'requestId', sortable: false, label: '아이디(닉네임)', thClass: 'col5' },
        { key: 'requestDate', sortable: false, label: '신청 날짜', thClass: 'col3' },
        { key: 'requestRank', sortable: false, label: '랭킹', thClass: 'col3' },
        { key: 'requestIp', sortable: false, label: '신청 IP', thClass: 'col3' },
        { key: 'requestCash', sortable: false, label: '환전 금액', thClass: 'col3' },
        { key: 'requestName', sortable: false, label: '환전자 명', thClass: 'col3' },
        { key: 'accountBank', sortable: false, label: '환전 계좌', thClass: 'col3' },
        { key: 'accountNumber', sortable: false, label: '계좌 번호', thClass: 'col6' },
        { key: 'confirmId', sortable: false, label: '승인아이디', thClass: 'col3' },
        { key: 'confirmIp', sortable: false, label: '승인IP', thClass: 'col3' },
        { key: 'confirmAt', sortable: false, label: '승인 일시', thClass: 'col3' },
        { key: 'confirmed', sortable: false, label: '상태', thClass: 'col6' },
        // { key: 'edit', sortable: false, label: '처리', thClass: 'col10' },
      ],
    }
  },
  computed: {
    ...noticeStore.mapGetters({
      fetchRequestAlarmsExchangeFinish: 'fetchRequestAlarmsExchangeFinish',
    }),

    // 검색필터된 Items
    filteredItems() {
      let filteredItems = this.fetchRequestAlarmsExchangeFinish
      if (this.searchFilter) {
        filteredItems = filteredItems.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      }
      // console.log('filteredItems', filteredItems)
      return filteredItems
    },

    // 검색필터된 Items => 페이징처리된 Item
    paginatedItems() {
      const totalFilteredItems = this.filteredItems.length
      const start = totalFilteredItems - (this.currentPage * this.perPage)
      const end = start + this.perPage

      const paginatedItems = this.filteredItems.slice(Math.max(0, start), end).reverse()

      this.totalRows = totalFilteredItems
      this.pageFrom = (this.currentPage - 1) * this.perPage + 1 // 현재 페이지의 첫 번째 항목
      this.pageTo = Math.min(this.totalRows, this.currentPage * this.perPage) // 현재 페이지의 마지막 항목 또는 전체 항목 수 중 작은 값
      this.pageOf = this.totalRows

      return paginatedItems
    },
  },
  watch: {
    selectedExchangeOptions() {
      this.fetchData()
    },
    periodFromFinish() {
      this.fetchData()
    },
    periodToFinish() {
      this.fetchData()
    },
  },
  created() {
    // console.log("UserTabUserExchange created()")
  },
  mounted() {
    // console.log("UserTabUserExchange mounted()")
    this.fetchData()
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchRequestAlarmsExchangeFinish: FETCH_REQUEST_ALARMS_EXCHANGE_FINISH,
    }),

    async fetchData() {
      // console.log("UserTabUserExchange fetchData()")
      // if (!this.selectedExchangeOptions) return;
      // console.log(
      //   "site", this.userData.site,
      //   "requestId", this.userData.site,
      //   "confirmed", this.selectedExchangeOptions,
      //   "periodFrom",this.periodFromFinish,
      //   "periodTo",this.periodToFinish
      // )

      await this.$fetchRequestAlarmsExchangeFinish({
        site: this.userData.site,
        requestId: this.userData.userid,
        periodFrom: this.periodFromFinish.split(' ')[0],
        periodTo: this.periodToFinish.split(' ')[0],
        ...(this.selectedExchangeOptions ? { confirmed: this.selectedExchangeOptions } : {}),
      })

      // console.log("fetchRequestAlarmsExchangeFinish", this.fetchRequestAlarmsExchangeFinish.length)
      this.exchangeItems = this.fetchRequestAlarmsExchangeFinish
    },
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      }
      return `${text.substring(0, maxLength)}...`
    },
    getTotal(field) {
      return this.paginatedItems.reduce((acc, item) => acc + item[field], 0)
    },
  },
}
</script>
