<template>
  <div>
    <b-card no-body>
      <b-tabs
        v-model="activeTab"
        @input="handleTabChange"
      >
        <b-tab
          title="수신함"
        >
          <!-- Table Fitler -->
          <b-row class="justify-content-between">
            <b-col
              lg="3"
              md="4"
            >
              <b-form-group
                v-if="false"
                class="font-small-3"
                label="구분"
                label-for="search-select"
                label-cols-lg="3"
                label-cols-md="4"
                label-align-md="left"
              >
                <b-form-select
                  id="section-select"
                  class="font-small-3 half-width"
                  size="sm"
                  style="color:rgb(229,166,48) !important; opacity: 0.7;"
                />
              </b-form-group>
            </b-col>
            <b-col
              lg="8"
              md="8"
            >
              <b-form-group
                class="font-small-3"
                label-align-sm="3"
                label="수신일자"
                label-for="message-period"
                label-cols-md="2"
                label-align-md="right"
              >
                <b-input-group
                  id="message-period"
                  class="calendar-size font-small-3"
                >
                  <b-form-datepicker
                    v-model="rcv.periodFrom"
                    class=""
                    boundary="window"
                    locale="ko"
                    placeholder="시작일"
                    aria-controls="example-input"
                    size="sm"
                    :max="rcv.periodTo"
                  />
                  <div class="pl-1 pr-1">
                    ~
                  </div>
                  <b-form-datepicker
                    v-model="rcv.periodTo"
                    class=""
                    boundary="window"
                    locale="ko"
                    placeholder="종료일"
                    aria-controls="example-input"
                    size="sm"
                    :min="rcv.periodFrom"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="justify-content-between">
            <b-col
              lg="3"
              md="4"
            >
              <b-form-group
                class="font-small-3"
                label="Show"
                label-for="message-select"
                label-cols-lg="3"
                label-cols-md="4"
                label-align-md="left"
              >
                <b-form-select
                  id="table-perPage"
                  v-model="rcv.perPage"
                  :options="rcv.perPageOptions"
                  size="sm"
                  :clearable="false"
                  class="half-width"
                />
              </b-form-group>
            </b-col>
            <b-col
              lg="8"
              md="8"
            >
              <b-form-group
                class="font-small-3"
                label="검색"
                label-for="table-searchFilter"
                label-cols-md="2"
                label-align-md="right"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="table-searchFilter"
                    v-model="rcv.searchFilter"
                    type="search"
                    placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!rcv.searchFilter"
                      @click="rcv.searchFilter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- table content -->
          <div>
            <b-table
              ref="messagesReceiveHistoyTable"
              responsive
              show-empty
              hover
              small
              empty-text="No matching records found"
              style="text-align: center; font-size: small"
              :items="paginatedRcvItems"
              :fields="rcv.messagesHistoyReceiveFields"
              :filter="rcv.filter"
              :filter-included-fields="rcv.filterOn"
              :sort-by.sync="rcv.sortBy"
              :sort-desc.sync="rcv.sortDesc"
              :sort-direction="rcv.sortDirection"
              :current-page="rcv.currentPage"
              @row-clicked="fncToggleRowDetails"
              @filtered="onFiltered"
            >
              <template #cell(index)="data">
                {{ rcv.totalRows - (rcv.perPage * (rcv.currentPage - 1) + data.index) }}
              </template>
              <template #cell(senderId)>
                <div style="min-width: 5rem">
                  관리자
                </div>
              </template>
              <template #cell(createdAt)="data">
                <div
                  style="min-width: 11rem;"
                  size="sm"
                >
                  {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}
                </div>
              </template>
              <template #cell(readAt)="data">
                <div
                  style="min-width: 11rem;"
                  size="sm"
                >
                  {{ data.item.readAt | formatDate('YYYY-MM-DD HH:mm:ss') }}
                </div>
              </template>
              <!-- row_detatils -->
              <template #row-details="row">
                <div v-if="row.item._showDetails">
                  <b-card
                    class="p-0 text-left"
                    style="background-color: #212026;"
                  >
                    <b> 내용 : </b> {{ row.item.message }}
                  </b-card>
                </div>
              </template>
            </b-table>
          </div>
          <!-- table footer -->
          <div>
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
                style="margin-bottom: 0.5rem;"
              >
                <span class="font-small-2">{{ rcv.pageFrom }}~{{ rcv.pageTo }} / 총 {{ rcv.pageOf }} </span>
              </b-col>
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center"
              >
                <b-pagination
                  v-model="rcv.currentPage"
                  :total-rows="rcv.totalRows"
                  :per-page="rcv.perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>

        <b-tab
          title="발신함"
        >
          <!-- Table Fitler -->
          <b-row class="justify-content-between">
            <b-col
              lg="3"
              md="4"
            >
              <b-form-group
                v-if="false"
                class="font-small-3"
                label="구분"
                label-for="search-select"
                label-cols-lg="3"
                label-cols-md="4"
                label-align-md="left"
              >
                <b-form-select
                  id="section-select"
                  class="font-small-3 half-width"
                  size="sm"
                  style="color:rgb(229,166,48) !important; opacity: 0.7;"
                />
              </b-form-group>
            </b-col>
            <b-col
              lg="8"
              md="8"
            >
              <b-form-group
                class="font-small-3"
                label="발신일자"
                label-for="message-period"
                label-cols-md="2"
                label-align-md="right"
              >
                <b-input-group
                  id="message-period"
                  class="calendar-size font-small-3"
                >
                  <b-form-datepicker
                    v-model="snd.periodFrom"
                    class=""
                    boundary="window"
                    locale="ko"
                    placeholder="시작일"
                    aria-controls="example-input"
                    size="sm"
                    :max="snd.periodTo"
                  />
                  <div class="pl-1 pr-1">
                    ~
                  </div>
                  <b-form-datepicker
                    v-model="snd.periodTo"
                    class=""
                    boundary="window"
                    locale="ko"
                    placeholder="종료일"
                    aria-controls="example-input"
                    size="sm"
                    :min="snd.periodFrom"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="justify-content-between">
            <b-col
              lg="3"
              md="4"
            >
              <b-form-group
                class="font-small-3"
                label="Show"
                label-for="table-perPage"
                label-cols-lg="3"
                label-cols-md="4"
                label-align-sm="left"
              >
                <b-form-select
                  id="table-perPage"
                  v-model="snd.perPage"
                  :options="snd.perPageOptions"
                  size="sm"
                  :clearable="false"
                  class="half-width"
                />
              </b-form-group>
            </b-col>
            <b-col
              lg="8"
              md="8"
            >
              <b-form-group
                class="font-small-3"
                label="검색"
                label-for="table-searchFilter"
                label-cols-md="2"
                label-align-md="right"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="table-searchFilter"
                    v-model="snd.searchFilter"
                    type="search"
                    placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!snd.searchFilter"
                      @click="snd.searchFilter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- table content -->
          <div>
            <b-table
              ref="messagesSendHistoyTable"
              responsive
              show-empty
              hover
              small
              empty-text="No matching records found"
              style="text-align: center; font-size: small"
              :items="paginatedSndItems"
              :fields="snd.messagesHistoySendFields"
              :filter="snd.filter"
              :filter-included-fields="rcv.filterOn"
              :sort-by.sync="snd.sortBy"
              :sort-desc.sync="snd.sortDesc"
              :sort-direction="snd.sortDirection"
              :current-page="snd.currentPage"
              @row-clicked="fncToggleRowDetails"
              @filtered="onFiltered"
            >
              <template #cell(index)="data">
                {{ snd.totalRows - (snd.perPage * (snd.currentPage - 1) + data.index) }}
              </template>
              <template #cell(senderId)="data">
                <div style="min-width: 5rem">
                  {{ data.item.senderId }}
                </div>
              </template>
              <template #cell(createdAt)="data">
                <div
                  style="min-width: 11rem;"
                  size="sm"
                >
                  {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}
                </div>
              </template>
              <!-- row_detatils -->
              <template #row-details="row">
                <div v-if="row.item._showDetails">
                  <b-card
                    class="p-0 text-left"
                    style="background-color: #212026;"
                  >
                    <b> 내용 : </b> {{ row.item.message }}
                  </b-card>
                </div>
              </template>
            </b-table>
          </div>
          <!-- table footer -->
          <div>
            <b-row>
              <b-col
                cols="4"
                sm="4"
                class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
                style="margin-bottom: 0.5rem;"
              >
                <span class="font-small-2">{{ snd.pageFrom }}~{{ snd.pageTo }} / 총 {{ snd.pageOf }} </span>
              </b-col>
              <b-col
                cols="4"
                sm="4"
                class="d-flex align-items-center justify-content-center"
              >
                <b-pagination
                  v-model="snd.currentPage"
                  :total-rows="snd.totalRows"
                  :per-page="snd.perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol,
  BTabs, BTab,
  BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormSelect, BFormTextarea, BButton,
  BFormDatepicker, BTable, BPagination,
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import {
  FETCH_MESSAGES_USER_FROM_ADMIN, FETCH_MESSAGES_USER_TO_ADMIN,
} from '@/store/messages/action'
import {
  CREATE_MESSAGES,
  UPDATE_MESSAGES_READ,
} from '@/store/messages/mutation'
import { createNamespacedHelpers } from 'vuex'
import * as moment from 'moment-timezone'

import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'

import store from '@/store'

const messagesStore = createNamespacedHelpers('messagesStore')

moment().tz('Asia/Seoul')
const fmt = 'YYYY-MM-DD HH:mm:ss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormTextarea,
    BButton,
    BFormDatepicker,
    BTable,
    BPagination,
    BModal,

    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userView')),
      userIp: localStorage.getItem('ip'),

      // Table Search & Option
      rcv: {
        periodFrom: moment(new Date()).startOf('month').format(fmt),
        periodTo: moment(new Date()).endOf('month').format(fmt),
        searchFilter: '',
        perPageOptions: [10, 25, 50, 100],
        currentPage: 1, // 현재 페이지
        perPage: 10, // 페이지 당 표시할 항목 수
        pageFrom: 0,
        pageTo: 0,
        pageOf: 0,
        totalRows: 0,
        sortBy: 'createdAt',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],

        // Table Fields
        messagesHistoyReceiveFields: [
          { key: 'index', label: 'No.' },
          { key: 'site', label: '사이트' },
          { key: 'title', label: '제목', tdClass: 'text-over text-left' },
          { key: 'senderId', label: '보낸사람' },
          { key: 'createdAt', label: '수신일자' },
          { key: 'readAt', label: '읽은시간' },
        ],
      },
      snd: {
        periodFrom: moment(new Date()).startOf('month').format(fmt),
        periodTo: moment(new Date()).endOf('month').format(fmt),
        searchFilter: '',
        perPageOptions: [10, 25, 50, 100],
        currentPage: 1, // 현재 페이지
        perPage: 10, // 페이지 당 표시할 항목 수
        pageFrom: 0,
        pageTo: 0,
        pageOf: 0,
        totalRows: 0,
        sortBy: 'createdAt',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],

        // Table Fields
        messagesHistoySendFields: [
          { key: 'index', label: 'No.' },
          { key: 'site', label: '사이트' },
          { key: 'title', label: '제목', tdClass: 'text-over text-left' },
          { key: 'senderId', label: '발신자' },
          { key: 'senderIp', label: '발신IP' },
          { key: 'createdAt', label: '발신일자' },
        ],
      },

      activeTab: 0, // 0:수신함, 1:발신함
      tabs: [
        { title: '수신함', isLoaded: false, type: 'rcv' },
        { title: '발신함', isLoaded: false, type: 'snd' },
      ],

      messages: {
        title: null,
        message: null,
      },

      required,
      between,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      digits,
      length,
      alphaDash,
    }
  },
  computed: {
    // computed 속성 정의
    ...messagesStore.mapGetters({
      fetchMessagesUserFromAdmin: 'fetchMessagesUserFromAdmin',
      fetchMessagesUserToAdmin: 'fetchMessagesUserToAdmin',
      updateMessagesRead: 'updateMessagesRead',
    }),

    // Table Search Items
    filteredRcvItems() {
      let filteredReceiveData = this.fetchMessagesUserFromAdmin
      if (this.rcv.searchFilter) {
        filteredReceiveData = filteredReceiveData.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.rcv.searchFilter.toLowerCase())))
      }
      return filteredReceiveData
    },
    // Table Search Items => Pagination Items
    paginatedRcvItems() {
      const start = (this.rcv.currentPage - 1) * this.rcv.perPage
      const end = start + this.rcv.perPage
      const paginatedData = this.filteredRcvItems.slice(start, end)

      this.rcv.totalRows = this.filteredRcvItems.length
      this.rcv.pageFrom = start + 1
      this.rcv.pageTo = start + paginatedData.length
      this.rcv.pageOf = this.rcv.totalRows

      return paginatedData
    },

    filteredSndItems() {
      let filteredSendData = this.fetchMessagesUserToAdmin
      if (this.snd.searchFilter) {
        filteredSendData = filteredSendData.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.snd.searchFilter.toLowerCase())))
      }
      return filteredSendData
    },
    // Table Search Items => Pagination Items
    paginatedSndItems() {
      const start = (this.snd.currentPage - 1) * this.snd.perPage
      const end = start + this.snd.perPage
      const paginatedData = this.filteredSndItems.slice(start, end)

      this.snd.totalRows = this.filteredSndItems.length
      this.snd.pageFrom = start + 1
      this.snd.pageTo = start + paginatedData.length
      this.snd.pageOf = this.snd.totalRows

      return paginatedData
    },
  },
  watch: {
    'snd.periodFrom': function () {
      this.fetchMessagesSendHistory()
    },
    'snd.periodTo': function () {
      this.fetchMessagesSendHistory()
    },
    'rcv.periodFrom': function () {
      this.fetchMessagesReceiveHistory()
    },
    'rcv.periodTo': function () {
      this.fetchMessagesReceiveHistory()
    },
  },
  mounted() {
    this.fetchMessagesReceiveHistory()
    this.fetchMessagesSendHistory()
  },
  methods: {
    ...messagesStore.mapActions({
      $createMessages: CREATE_MESSAGES,
      $fetchMessagesUserFromAdmin: FETCH_MESSAGES_USER_FROM_ADMIN,
      $fetchMessagesUserToAdmin: FETCH_MESSAGES_USER_TO_ADMIN,
      $updateMessagesRead: UPDATE_MESSAGES_READ,
    }),
    handleTabChange() {
      if (!this.tabs[this.activeTab].isLoaded) {
        this.tabs[this.activeTab].isLoaded = true
      }
    },
    async fetchMessagesReceiveHistory(type) {
      const msgType = type || this.tabs[this.activeTab].type
      const param = {
        site: this.userData.site,
        userid: this.userData.userid,
        periodFrom: this[msgType].periodFrom,
        periodTo: this[msgType].periodTo,
      }
      await this.$fetchMessagesUserFromAdmin(param)
    },
    async fetchMessagesSendHistory(type) {
      const msgType = type || this.tabs[this.activeTab].type
      const param = {
        site: this.userData.site,
        userid: this.userData.userid,
        periodFrom: this[msgType].periodFrom,
        periodTo: this[msgType].periodTo,
      }
      await this.$fetchMessagesUserToAdmin(param)
    },
    // Table Methods
    async fncToggleRowDetails(item) {
      // console.log('item ::', item)
      this.$set(item, '_showDetails', !item._showDetails)
    },

    // Modal Methods
    async btnSend() {
      if (!await validation(this.$refs.simpleRules)) return false

      try {
        const result = await fncShowSwal('send')
        if (result.isConfirmed) {
          await this.$createMessages({
            site: this.$site,
            userid: this.userData.userid,
            ip: this.userIp,
            messages: {
              ...this.messages,
            },
          }).then(async res => {
            if (res.status) {
              await fncShowSwal('success', '메세지 전송완료')
              this.btnReset()
              this.btnClose()
            }
          })
        }
      } catch (e) {
        console.error('[Messages] Send', e)
        await fncShowSwal('error', '메세지 전송실패!')
      }
    },
    async btnReset() {
      this.messages = {
        title: null,
        message: null,
      }
    },
    btnShow() {
      this.$bvModal.show('messages-send-from')
    },
    btnClose() {
      this.$bvModal.hide('messages-send-from')
    },
    fnShownModal() {
      this.btnReset()
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style lang="scss">
.per-page-selector {
  width: 200px;
}

/* 글자길이가 길어질경우 ...로 표시 */
.text-over {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}
///* 웹에서 텍스트를 선택하는 기능을 비활성화하는 역할 */
//.no-select {
//  /* 표준 방식 */
//  user-select: none;
//
//  /* 벤더 프리픽스 추가 */
//  -webkit-user-select: none; /* Chrome, Safari, Opera */
//  -moz-user-select: none; /* Firefox */
//  -ms-user-select: none; /* IE/Edge */
//}
//
////Modal
//.modal-dialog {
//  max-width: 90rem;
//}
//
//.table-container {
//  max-height: 400px;
//  overflow-y: auto;
//  margin-bottom: 2rem;
//}
//
//.table-scroll th {
//  position: sticky;
//  top: 0rem;
//  background-color: #fff;
//  z-index: 1;
//}
.no-select {
  user-select: none;
}
</style>
